<template>
	<div class="slide-content">
		<base-container>
			<div class="forbes-container">
                <base-svg :name="value.forbes_icon" />
			</div>
			<div class="slide-title-wrapper">
				<base-svg name="slider_frame"/>
					<cms-text
						v-for="(text, i) in this.value.slider"
						:key="`${text}.${i}.slide_title`"
						:props="{variant: 'slider', color: 'white'}"
						:value="`${prefix}.slider.${i}.slide_title`"
						:class="{active: i === activeSlide}"
						class="slide-text"
					/>
					<cms-text
						v-for="(text, i) in this.value.slider"
						:key="`${text}.${i}.slide_subtitle`"
						:props="{variant: 'slider-subheading', color: 'white'}"
						:value="`${prefix}.slider.${i}.slide_subtitle`"
						:class="{active: i === activeSlide}"
						class="slide-text"
					/>
			</div>
			<div class="slide-texts">
				<cms-text
						v-for="(text, i) in this.value.slider"
						:key="`${text}.${i}.text_under_title`"
						:props="{variant: 'paragraph', color: 'white'}"
						:value="`${prefix}.slider.${i}.text_under_title`"
						:class="{active: i === activeSlide}"
						class="slide-text"
					/>
			</div>
			<cms-multi-section
				v-for="(image, i) in this.value.slider"
				:key="`${image}.${i}.slide_photo.path`"
				class="slide-buttons"
				:value="`${prefix}.slider.${i}.slider_buttons`"
				:class="{active: i === activeSlide}"
			/>
		<div class="scroll-down" @click="handleScrollDown('hero')">
			<base-font marginReset color="white">{{generalData.btn_primary_text}}</base-font>
			<base-svg class="scroll-down-svg" name="scroll_down"/>
		</div>
		</base-container>
	</div>
</template>
<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'

export default {
	components: {
		CmsMultiSection
	},
	props: {
		prefix: String,
		value: Object,
		activeSlide: Number
	},
	methods: {
		handleScrollDown (element) {
			const menuOffset = document.querySelector('.nav-wrapper').offsetHeight
			const section = document.getElementById(element)
			window.scrollTo({
				top: (section.offsetTop - menuOffset),
				left: 0,
				behavior: 'smooth'
			})
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('typography')
	},
	computed: {
		generalData () {
			return this.$app.translator.get('typography')
		}
	}
}
</script>
<style lang="scss">
@keyframes scrollDownMove {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0);
	}
}
.slide {
	&-texts {
		position: relative;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		@include media-breakpoint-up(xl) {
			max-width: 75%;
			display: block;
		}
		@include media-breakpoint-up(xxl) {
			max-width: 60%;
		}
	}
	&-text {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: .3s ease-in;
		z-index: 3;
		display: none;

		&.active {
			top: 0;
			left: 0;
			position: relative;
			opacity: 1;
			display: block;
		}
	}
	.scroll-down {
		display: none;
		@include media-breakpoint-up(xl){
			display: flex;
			align-items: center;
			flex-direction: column;
			position: absolute;
			cursor: pointer;
			margin-left: 210px;
			bottom: -8vh;
			left: calc((100vw - 1140px)/2);
		}
		@include media-breakpoint-up(xxl) {
			left: calc((100vw - 1410px)/2);
		}
		@media screen and (min-width: 1920px) {
			bottom: -15vh;
		}
		.scroll-down-svg {
			animation: scrollDownMove 1s linear infinite paused;
		}

		@include media-breakpoint-up(xl) {
			&:hover {
				.scroll-down-svg {
					animation-play-state: running;
				}
			}
		}
		&-svg {
			width: 15px;
			margin-top: 5px;
		}
	}
	&-content {
		position: absolute;
		width: 100%;
		top: 0rem;
		left: 0;
		display: flex;
		flex-direction: column;
		z-index: 3;
		@include media-breakpoint-up(xl) {
			top: 10rem;
		}
		@include media-breakpoint-up(xxl) {
			top: 12.5rem;
		}

		.forbes-container {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			height: 70px;
			@include media-breakpoint-landscape {
				height: 70px;
			}
			@include media-breakpoint-up(lg) {
				height: 80px;
			}
			@include media-breakpoint-up(xxl) {
				height: 140px;
			}
            .svg-icon {
                margin-top: 1.5rem;
                height: 120px;
                width: 120px;
                fill: $white;
                @include media-breakpoint-up(lg) {
                    height: 100%;
                    margin-right: 2rem;
                }
                @include media-breakpoint-up(xl) {
                    width: 140px;
                }
                @include media-breakpoint-up(xxl) {
                    margin-right: 0;
                    width: 160px;
                }
            }
		}
		// .forbes-icon {
		// 	height: 10rem;
		// 	width: 10rem;
		// 	@include media-breakpoint-up(xl) {
		// 		height: 8rem;
		// 		width: 8rem;
		// 	}
		// 	@include media-breakpoint-up(xxl) {
		// 		width: 14rem;
		// 		height: 14rem;
		// 	}
		// }
	}
	.slide-title-wrapper {
		position: relative;
		margin-bottom: 3rem;
		padding-bottom: 2rem;
		margin-left: 1rem;
        margin-top: 7rem;
        @include media-breakpoint-landscape {
            margin-top: 3rem;
            margin-bottom: 1rem;
        }
		@include media-breakpoint-up(xl) {
            margin-top: 0;
			padding-bottom: 4rem;
			margin-bottom: 3rem;
			margin-left: 3rem;
		}
		@include media-breakpoint-up(xxl) {
			padding-bottom: 5rem;
			margin-bottom: 5rem;
			margin-left: 4rem;
		}
		.svg-icon {
			position: absolute;
			top: -2rem;
			left: -1rem;
			max-width: 200px;
			@include media-breakpoint-up(xl) {
				top: -4rem;
				left: -3rem;
				max-width: 425px;
			}
			@include media-breakpoint-up(xxl) {
				top: -5rem;
				left: -4rem;
				max-width: 550px;
			}
		}
	}
	&-buttons {
		display: none;
		z-index: 4;
		@include media-breakpoint-landscape {
			display: none;
		}
		&.active {
			display: block;
			@include media-breakpoint-landscape {
				display: none;
			}
		}
	}
}
</style>
