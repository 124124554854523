<template>
<div class="slider-pagination">
	<base-container>
		<nav>
			<ul class="pagination-list">
				<li
					v-for="(item, i) in length"
					:key="i"
					@click.stop="$emit('goToHandler', i)"
					:class="{
						active: i === activeSlide
					}"
					class="pagination-item"
				></li>
			</ul>
		</nav>
	</base-container>
</div>
</template>
<script>
export default {
	props: {
		length: Number,
		activeSlide: Number
	}
}
</script>
<style lang="scss" scoped>
.slider-pagination {
	width: 100%;
	position: absolute;
	bottom: 40px;
	left: 0;
	z-index: 10;
	left: 15px;
	@media screen and (max-height: 630px) {
		display: none;
	}
	@include media-breakpoint-up(sm) {
		//left: 50%;
		//transform: translateX(-50%);
	}
	@include media-breakpoint-landscape {
		bottom: 0px;
        display: block;
	}
	@include media-breakpoint-up(xl){
		bottom: 10px;
		transform: none;
		//left: calc((100vw - 1125px)/2);
	}
	@include media-breakpoint-up(xxl){
		//left: calc((100vw - 1410px)/2);
	}
	.pagination-list {
		display: flex;
		list-style: none;
		padding: 0;

	}
	.pagination-item {
		background-color: rgba($white, .1);
		margin-right: 1rem;
		cursor: pointer;
		width: 60px;
		height: 5px;
		@include transition;
		@include media-breakpoint-up(xl) {
			width: 90px;
			height: 7px;
		}
		&.active {
			background-color: $white;
		}
	}
}
</style>
