import { render, staticRenderFns } from "./SliderPagination.vue?vue&type=template&id=e02840fe&scoped=true"
import script from "./SliderPagination.vue?vue&type=script&lang=js"
export * from "./SliderPagination.vue?vue&type=script&lang=js"
import style0 from "./SliderPagination.vue?vue&type=style&index=0&id=e02840fe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e02840fe",
  null
  
)

export default component.exports