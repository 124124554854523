<template>
    <div class="slider">
        <div class="slide">
            <div class="main-photo-wrapper">
                <div class="slide-img active" v-if="!loaded" v-background="getBackgroundImage(0)" />
                <div
                    v-else
                    v-for="(image, i) in this.value.slider"
                    :key="i"
                    class="slide-img"
                    :class="{
                        active: i === activeSlideIndex,
                        prev: i < activeSlideIndex,
                        next: i > activeSlideIndex
                    }"
                    v-background="getBackgroundImage(i)"
                />

            </div>
            <slider-content :activeSlide="activeSlideIndex" :prefix="prefix" :value="value"/>
            <slider-pagination
                    v-if="loaded"
                    @goToHandler="goTo"
                    :activeSlide="activeSlideIndex"
                    :length="this.value.slider.length"
                />
        </div>
    </div>
</template>

<script>
import SliderContent from './components/SliderContent'
import SliderPagination from './components/SliderPagination'

export default {
	data: () => ({
		activeSlideIndex: 0,
		loaded: false
	}),
	components: {
		SliderContent,
		SliderPagination
	},
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		goTo (index) {
			this.activeSlideIndex = index
			clearInterval(this.timer)
			this.startInterval()
		},
		startInterval () {
			this.timer = setInterval(this.next, 11000)
		},
		next () {
			if (this.activeSlideIndex === this.value.slider.length - 1) this.activeSlideIndex = 0
			else this.activeSlideIndex++
		},
		getBackgroundImage (index) {
			return {
				src: this.value.slider[index].slide_photo.path,
				breakpoints: {
					base: { width: 660, height: 640, position: 'entropy' },
					sm: { width: 760, height: 440, position: 'entropy' },
					md: { width: 768, height: 800, position: 'entropy' },
					landscape: { width: 915, height: 600, position: 'entropy' },
					lg: { width: 1024, height: 768, position: 'entropy' },
					xl: { width: 1280, height: 1024 },
					xxl: { width: 1920, height: 1080 }
				}
			}
		},
		renderImages () {
			setTimeout(() => {
				this.setLoaded()
			}, 10000)
		},
		setLoaded () {
			this.loaded = true
		}
	},
	mounted () {
		this.isFirstLoad ? this.renderImages() : this.setLoaded()
		this.startInterval()
	},
	computed: {
		isFirstLoad () {
			return !(this.$app.page.from.fullPath.length > 1)
		}
	}
}
</script>
<style lang="scss" scoped>
.main-photo-wrapper {
	position: relative;
	height: 100%;
}
.slider {
	height: 100vh;
	overflow: hidden;
	padding-top: 55px;
	@include media-breakpoint-up(md) {
		height: 60vh;
	}
	@include media-breakpoint-landscape {
		height: 100vh;
	}
	@include media-breakpoint-up(lg) {
		height: 70vh;
	}
	@include media-breakpoint-up(xl) {
		padding: 0;
		height: 100vh;
	}
	@media screen and (min-width: 2000px) {
		height: 75vh;
	}
}
.slide {
	position: relative;
	height: 100%;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgb(28,28,28);
		background: linear-gradient(180deg, rgba(28,28,28,1) 0%, rgba(28,28,28,0) 100%);
		z-index: 1;
	}
	&-img {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: all .5s ease;
		pointer-events: none;
		height: 100%;
		width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
		&.prev {
			transform: translateX(100px);
			z-index: 2;
		}
		&.next {
			transform: translateX(-100px);
		}
		&.active {
			position: relative;
			transform: translateX(0);
			opacity: 1;
			pointer-events: inherit;
		}
	}
}
</style>
